<template>
  <v-card style="height: auto">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center">
        <v-col>
          <span class="text-h6 font-weight-regular mb-0 pb-0">
            {{ station.rastless_product.toUpperCase() }} -
            {{ station.station_name }}</span
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-row class="mb-5">
            <v-col
              >{{ $t("baseline.min") }}
              <p
                :style="{
                  color: getStatusColor(
                    station.overall_statistics.min,
                    thresholds
                  )
                }"
                class="my-0  text-h4"
              >
                {{ station.overall_statistics.min.toFixed(2) }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col
              >{{ $t("baseline.max") }}
              <p
                :style="{
                  color: getStatusColor(
                    station.overall_statistics.max,
                    thresholds
                  )
                }"
                class="my-0 text-h4"
              >
                {{ station.overall_statistics.max.toFixed(2) }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col
              >{{ $t("baseline.median") }}
              <p
                :style="{
                  color: getStatusColor(
                    station.overall_statistics.median,
                    thresholds
                  )
                }"
                class="my-0 text-h4"
              >
                {{ station.overall_statistics.median.toFixed(2) }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              >{{ $t("baseline.standardDeviation") }}
              <p class="my-0 text-h5">
                {{ station.overall_statistics.stddev.toFixed(2) }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col
              >{{ $t("baseline.numberOfAlerts") }}
              <p class="my-0 text-h5">
                {{ alertCount }}
              </p></v-col
            >
            <v-divider class="mt-4" vertical />
            <v-col>
              {{ $t("baseline.numberOfScenes") }}
              <p class="my-0 text-h5">
                {{ station.overall_statistics.nb_scenes.toFixed(0) }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" class="pb-5">
          <div style="background-color: #E9EFF5">
            <v-row>
              <v-col>
                <p align="center" class="text-h6 font-weight-light">
                  {{ $t("baseline.5HighestValues") }}
                </p>
                <v-row>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.date") }}
                  </v-col>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.value") }} [{{ station.rastless_unit }}]
                  </v-col>
                </v-row>
                <v-row v-for="date in maxData" :key="date.date">
                  <v-col align="center" class="py-2">{{ date.date }}</v-col>
                  <v-col align="center" class="py-2">{{ date.value }}</v-col>
                </v-row>
              </v-col>
              <v-col>
                <p align="center" class="text-h6 font-weight-light">
                  {{ $t("baseline.5LowestValues") }}
                </p>
                <v-row>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.date") }}
                  </v-col>
                  <v-col align="center" class="font-weight-bold py-2">
                    {{ $t("baseline.value") }} [{{ station.rastless_unit }}]
                  </v-col>
                </v-row>
                <v-row v-for="date in minData" :key="date.date">
                  <v-col align="center" class="py-2">{{ date.date }}</v-col>
                  <v-col align="center" class="py-2">{{ date.value }}</v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import baselineMixin from "@/core/mixins/baseline.mixin";

export default {
  name: "StatisticsCompare",
  props: {
    thresholds: { type: Object },
    years: { type: Array },
    region: null,
    station: { type: Object }
  },
  data: () => ({
    minData: null,
    maxData: null,
    alertCount: 0
  }),
  mixins: [baselineMixin],
  methods: {
    getHighestAndLowestValues(data) {
      const Arrdata = [];
      for (const [key, value] of Object.entries(data)) {
        if (value.length > 1) {
          value.forEach(value => {
            Arrdata.push({ date: key, value: value });
          });
        } else {
          Arrdata.push({ date: key, value: value[0] });
        }
      }
      let sortArr = Arrdata.sort(function(a, b) {
        return a.value - b.value;
      });
      this.maxData = sortArr.slice(Math.max(sortArr.length - 5, 0)).reverse();
      this.minData = sortArr.slice(0, 5);
      return Arrdata;
    },
    countAlerts(data) {
      if (this.thresholds?.threshold_alert) {
        data.forEach(step => {
          if (step.value >= this.thresholds.threshold_alert) {
            this.alertCount += 1;
          }
        });
      }
    }
  },
  watch: {
    async station() {
      const data = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.station.id,
        "daily",
        `${this.years[0]}-01-01`,
        `${this.years[this.years.length - 1]}-12-31`
      );
      const arrData = this.getHighestAndLowestValues(data);
      this.countAlerts(arrData);
    }
  },
  async created() {
    const data = await this.fetchStatisticsWithinTimeRange(
      this.region,
      this.station.id,
      "daily",
      `${this.years[0]}-01-01`,
      `${this.years[this.years.length - 1]}-12-31`
    );
    const arrData = this.getHighestAndLowestValues(data);
    this.countAlerts(arrData);
  }
};
</script>

<style scoped></style>
