<template>
  <v-card style="height: 282px">
    <v-card-text class="black--text pt-2 ">
      <v-row style="align-items: center" no-gutters>
        <v-col>
          <span class="text-h6 font-weight-regular">
            {{ selectedStations[0].rastless_product.toUpperCase() }} -
            {{ $t("baseline.monthlyValues") }}</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" v-if="!loading">
          <year-filter
            :years="years"
            @filteredYears="changeYears"
          ></year-filter>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      ref="plot"
      id="monthly-values-compare"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '70%'
      }"
    ></div>
  </v-card>
</template>

<script>
import YearFilter from "@/core/components/baseline/YearFilter.vue";
import { mapState } from "vuex";
import baselineMixin from "@/core/mixins/baseline.mixin";
import plotly from "plotly.js-dist";

export default {
  name: "MonthlyValuesCompare",
  components: { YearFilter },
  mixins: [baselineMixin],
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    years: { type: Array },
    region: null,
    station1: {},
    station2: {}
  },
  data: () => ({
    selectedStartYear: null,
    selectedEndYear: null,
    data: null,
    boxPlotData: [],
    loading: true
  }),
  computed: {
    ...mapState("baseline", ["monthsDict", "selectedStations"]),
    colorArrays() {
      return [
        [
          "#40C7E4",
          "#1D4180",
          "#1460AB",
          "#039BE5",
          "#11274D",
          "#4C769D",
          "#1F4366",
          "#89BACE"
        ],
        [
          "#333333",
          "#999999",
          "#bfbfbf",
          "#595959",
          "#cccccc",
          "#4d4d4d",
          "#737373",
          "#0d0d0d"
        ],
        [
          "#F2B90A",
          "#D17F05",
          "#F9BC6C",
          "#FF9800",
          "#907215",
          "#CC9A00",
          "#8C5401",
          "#E58900"
        ]
      ];
    }
  },

  methods: {
    updatePLot(data, layout) {
      plotly.react("monthly-values-compare", data, layout);
    },
    changeYears(filteredyears) {
      filteredyears.sort();
      const data = this.createSinglePlotData(filteredyears, this.colorArrays);
      this.updatePLot(data, this.layout);
    },
    createSinglePlotData(years, colorArray) {
      let singlePlotData = [];
      years.forEach((year, index) => {
        this.selectedStations.forEach(async (station, id) => {
          const data = this.data[id];
          const x = [];
          const y = [];
          this.monthsDict.forEach(month => {
            if (data.datetimes.includes(`${year}-${month.val}`)) {
              const index = data.datetimes.indexOf(`${year}-${month.val}`);
              x.push(`${this.$t(`${month.label}`)}`);
              y.push(data.values[index]);
            } else {
              x.push(`${this.$t(`${month.label}`)}`);
              y.push(undefined);
            }
          });
          singlePlotData.push({
            name: `${year} ${station.station_name}`,
            y: y,
            x: x,
            type: "bar",
            marker: { color: colorArray[id][index] }
          });
        });
      });
      return singlePlotData;
    },
    createPlot(data) {
      this.configs = this.getPLotConfigs();
      this.layout = this.getBarLayoutCompare(
        this.selectedStations[0].rastless_unit
      );
      plotly.newPlot("monthly-values-compare", data, this.layout, this.configs);
    }
  },
  watch: {
    async selectedStations() {
      this.loading = true;
      this.selectedStartYear = this.years[0];
      this.selectedEndYear = this.years[this.years.length - 1];
      this.dataStation1 = await this.fetchStatisticsMeanWithinTimeRange(
        this.region,
        this.selectedStations[0].id,
        "monthly",
        "mean",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.dataStation2 = await this.fetchStatisticsMeanWithinTimeRange(
        this.region,
        this.selectedStations[1].id,
        "monthly",
        "mean",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.data = [this.dataStation1, this.dataStation2];
      this.plotData = this.createSinglePlotData(this.years, this.colorArrays);
      this.createPlot(this.plotData);
      this.loading = false;
    },
    showThresholds() {
      if (this.showThresholds) {
        const layout = this.addThresholdLayout(
          this.layout,
          this.thresholds.threshold_alert,
          this.thresholds.threshold_warning
        );
        this.updatePLot(this.plotData, layout);
      } else {
        this.layout = this.getBarLayoutCompare(
          this.selectedStations[0].rastless_unit
        );
        this.updatePLot(this.plotData, this.layout);
      }
    }
  },
  async created() {
    this.selectedStartYear = this.years[0];
    this.selectedEndYear = this.years[this.years.length - 1];
    this.dataStation1 = await this.fetchStatisticsMeanWithinTimeRange(
      this.region,
      this.selectedStations[0].id,
      "monthly",
      "mean",
      `${this.selectedStartYear}-01-01`,
      `${this.selectedEndYear}-12-31`
    );
    this.dataStation2 = await this.fetchStatisticsMeanWithinTimeRange(
      this.region,
      this.selectedStations[1].id,
      "monthly",
      "mean",
      `${this.selectedStartYear}-01-01`,
      `${this.selectedEndYear}-12-31`
    );
    this.data = [this.dataStation1, this.dataStation2];
    this.plotData = this.createSinglePlotData(this.years, this.colorArrays);
    this.createPlot(this.plotData);
    this.loading = false;
  }
};
</script>

<style scoped></style>
