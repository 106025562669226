<template>
  <v-card style="height: 275px">
    <v-card-text class="black--text pt-2">
      <v-row no-gutters style="align-items: center">
        <v-col cols="auto">
          <span class="text-h6 font-weight-regular">
            {{ selectedStations[0].rastless_product.toUpperCase() }} -
            {{ $t("baseline.singleValues") }}</span
          >
        </v-col>
        <v-col
          class="pl-0"
          cols="auto"
          v-if="!loading"
          style="min-width: 200px;"
        >
          <v-switch
            :label="
              showInSituData
                ? $t('baseline.hideInsitu')
                : $t('baseline.showInsitu')
            "
            color="primary"
            hide-details
            :disabled="!hasInSituData"
            v-model="showInSituData"
            class="mt-0 pa-0 pl-1 float-left text-body-2"
          ></v-switch>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="auto"
          class="pr-2"
          v-if="!loading"
          style="min-width: 100px;"
        >
          <period-filter
            :years="showInSituData ? combineYears : years"
            @filteredYears="changeYears"
            @filteredPeriod="changeTimePeriod"
          ></period-filter>
        </v-col>
      </v-row>
    </v-card-text>
    <v-row class="px-3">
      <div
        ref="mainplot"
        id="singleValuesPlot"
        :style="{
          top: 0,
          bottom: 0,
          width: 'calc(60% - 5px)',
          height: '200px',
          marginRight: '5px'
        }"
      ></div>
      <div
        ref="plot"
        id="singleValuesBoxPlot"
        :style="{
          top: 0,
          bottom: 0,
          width: '20%',
          height: '200px'
        }"
      ></div>
      <div
        ref="plot"
        id="singleValuesBoxPlot2"
        :style="{
          top: 0,
          bottom: 0,
          width: '20%',
          height: '200px'
        }"
      ></div>
      <v-overlay v-if="loading" opacity="0.1" absolute>
        <div>
          <v-progress-circular
            indeterminate
            color="primary"
            size="64"
          ></v-progress-circular>
        </div>
      </v-overlay>
    </v-row>
  </v-card>
</template>

<script>
import PeriodFilter from "@/core/components/baseline/PeriodFilter.vue";
import plotly from "plotly.js-dist";
import baselineMixin from "@/core/mixins/baseline.mixin";
import { mapState } from "vuex";
import moment from "moment";
import dataManagementMixin from "@/core/mixins/dataManagement.mixin";
import inSituDataMixin from "@/core/mixins/inSituData.mixin";

export default {
  name: "SingleValuesCompare",
  components: { PeriodFilter },
  mixins: [baselineMixin, dataManagementMixin, inSituDataMixin],
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    years: { type: Array }
  },
  data: () => ({
    data: {},
    timeseries1: null,
    timeseries2: null,
    loading: true,
    plotData: null,
    boxPlotData1: null,
    boxPlotData2: null,
    plotInSituData: null,
    boxPlotInSituDataData1: null,
    boxPlotInSituDataData2: null,
    inSituData1: null,
    inSituData2: null,
    combineYears: [],
    showInSituData: false,
    yearsToFilterInSituData: [],
    periodToFilterInSituData: [],
    filterBy: [],
    hasInSituData: false
  }),
  computed: {
    ...mapState("baseline", ["selectedStations", "compareColors"]),
    ...mapState("inSitu", ["compareColorsInSituData"])
  },
  methods: {
    updatePlot(data, layout) {
      plotly.react("singleValuesPlot", data, layout);
    },
    changeYears(years) {
      this.yearsToFilterInSituData = years;

      // create timeseries plot
      let data = JSON.parse(
        JSON.stringify([this.timeseries1, this.timeseries2])
      );
      data.forEach(dataset => {
        let datetimes = [];
        let values = [];
        dataset.datetime.forEach((datetime, index) => {
          if (years.includes(datetime.split("-")[0])) {
            datetimes.push(datetime);
            values.push(dataset.data[index]);
          }
        });
        dataset.datetime = datetimes;
        dataset.data = values;
      });
      this.createPlot(data);

      let inSituData = JSON.parse(
        JSON.stringify([this.inSituData1, this.inSituData2])
      );
      inSituData.forEach(dataset => {
        let datetimesInSitu = [];
        let valuesInSitu = [];
        dataset.date_times.forEach((datetime, index) => {
          if (years.includes(datetime.split("-")[0])) {
            datetimesInSitu.push(datetime);
            valuesInSitu.push(dataset.values[index]);
          }
        });
        dataset.date_times = datetimesInSitu;
        dataset.values = valuesInSitu;
      });

      //time series 1 - box plot
      let timeSeries1 = JSON.parse(JSON.stringify(this.timeseries1));

      let datetimesT1 = [];
      let valuesT1 = [];

      timeSeries1.datetime.forEach((datetime, index) => {
        if (years.includes(datetime.split("-")[0])) {
          datetimesT1.push(datetime);
          valuesT1.push(timeSeries1.data[index]);
        }
      });
      timeSeries1.datetime = datetimesT1;
      timeSeries1.data = valuesT1;
      this.createBoxPlotStation1([timeSeries1]);

      //time series 2 - box plot
      let timeSeries2 = JSON.parse(JSON.stringify(this.timeseries2));

      let datetimesT2 = [];
      let valuesT2 = [];

      timeSeries2.datetime.forEach((datetime, index) => {
        if (years.includes(datetime.split("-")[0])) {
          datetimesT2.push(datetime);
          valuesT2.push(timeSeries2.data[index]);
        }
      });
      timeSeries2.datetime = datetimesT2;
      timeSeries2.data = valuesT2;

      this.createBoxPlotStation2([timeSeries2]);

      // filter by years - In Situ Data Plot
      //in situ data 1
      let inSituData1 = JSON.parse(JSON.stringify(this.inSituData1));
      let datetimesISD1 = [];
      let valuesISD1 = [];

      inSituData1.date_times.forEach((datetime, index) => {
        if (years.includes(datetime.split("-")[0])) {
          datetimesISD1.push(datetime);
          valuesISD1.push(inSituData1.values[index]);
        }
      });
      inSituData1.date_times = datetimesISD1;
      inSituData1.values = valuesISD1;

      //in situ data 2
      let inSituData2 = JSON.parse(JSON.stringify(this.inSituData2));

      let datetimesISD2 = [];
      let valuesISD2 = [];

      inSituData2.date_times.forEach((datetime, index) => {
        if (years.includes(datetime.split("-")[0])) {
          datetimesISD2.push(datetime);
          valuesISD2.push(inSituData2.values[index]);
        }
      });
      inSituData2.date_times = datetimesISD2;
      inSituData2.values = valuesISD2;

      if (this.showInSituData) {
        this.createInSituPlot(inSituData);
        this.createInSituBoxPlot1([inSituData1]);
        this.createInSituBoxPlot2([inSituData2]);
      }
    },
    changeTimePeriod(timePeriod) {
      // create timeseries plot
      this.periodToFilterInSituData = timePeriod;

      let data = JSON.parse(
        JSON.stringify([this.timeseries1, this.timeseries2])
      );
      data.forEach(dataset => {
        let datetimes = [];
        let values = [];
        dataset.datetime.forEach((datetime, index) => {
          if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
            datetimes.push(datetime);
            values.push(dataset.data[index]);
          }
        });
        dataset.data = values;
        dataset.datetime = datetimes;
      });
      this.createPlot(data);

      // create in situ data plot

      let inSituData = JSON.parse(
        JSON.stringify([this.inSituData1, this.inSituData2])
      );

      inSituData.forEach(dataset => {
        let datetimesInSituData = [];
        let valuesInSitu = [];
        dataset.date_times.forEach((datetime, index) => {
          if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
            datetimesInSituData.push(datetime);
            valuesInSitu.push(dataset.values[index]);
          }
        });
        dataset.values = valuesInSitu;
        dataset.date_times = datetimesInSituData;
      });

      // create box plot - time series 1
      let timeSeries1 = JSON.parse(JSON.stringify(this.timeseries1));

      let datetimesT1 = [];
      let valuesT1 = [];

      timeSeries1.datetime.forEach((datetime, index) => {
        if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
          datetimesT1.push(datetime);
          valuesT1.push(timeSeries1.data[index]);
        }
      });
      timeSeries1.datetime = datetimesT1;
      timeSeries1.data = valuesT1;

      this.createBoxPlotStation1([timeSeries1]);

      // create box plot - time series 2

      let timeSeries2 = JSON.parse(JSON.stringify(this.timeseries2));

      let datetimesT2 = [];
      let valuesT2 = [];

      timeSeries2.datetime.forEach((datetime, index) => {
        if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
          datetimesT2.push(datetime);
          valuesT2.push(timeSeries2.data[index]);
        }
      });
      timeSeries2.datetime = datetimesT2;
      timeSeries2.data = valuesT2;

      this.createBoxPlotStation2([timeSeries2]);

      // create box plot - in situ data 1

      let inSituData1 = JSON.parse(JSON.stringify(this.inSituData1));
      let datetimesISD1 = [];
      let valuesISD1 = [];

      inSituData1.date_times.forEach((datetime, index) => {
        if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
          datetimesISD1.push(datetime);
          valuesISD1.push(inSituData1.values[index]);
        }
      });
      inSituData1.date_times = datetimesISD1;
      inSituData1.values = valuesISD1;

      // create box plot - in situ data 2

      let inSituData2 = JSON.parse(JSON.stringify(this.inSituData2));
      let datetimesISD2 = [];
      let valuesISD2 = [];

      inSituData2.date_times.forEach((datetime, index) => {
        if (moment(datetime).isBetween(timePeriod[0], timePeriod[1])) {
          datetimesISD2.push(datetime);
          valuesISD2.push(inSituData2.values[index]);
        }
      });
      inSituData2.date_times = datetimesISD2;
      inSituData2.values = valuesISD2;

      if (this.showInSituData) {
        this.createInSituPlot(inSituData);
        this.createInSituBoxPlot1([inSituData1]);
        this.createInSituBoxPlot2([inSituData2]);
      }
    },
    createPlot(data) {
      this.plotData = [];
      data.forEach((data, idx) => {
        this.plotData.push({
          type: "scatter",
          mode: "markers",
          marker: { color: this.compareColors[idx] },
          x: data.datetime,
          y: data.data
        });
      });
      const config = this.getPLotConfigs();
      this.layout = this.getLayout(this.selectedStations[0].rastless_unit);
      var graphDiv = document.getElementById("singleValuesPlot");
      plotly.newPlot(graphDiv, this.plotData, this.layout, config);

      graphDiv.on("plotly_relayout", function(eventdata) {
        this.yrange = [
          eventdata["yaxis.range[0]"],
          eventdata["yaxis.range[1]"]
        ];
      });
    },
    createBoxPlotStation1(data) {
      this.boxPlotData1 = [];
      data.forEach(data => {
        this.boxPlotData1.push({
          type: "box",
          y: data.data,
          name: `${this.selectedStations[0].station_name}`,
          marker: {
            color: this.compareColors[0]
          }
        });
      });
      const config = this.getPLotConfigs();
      const layout = {
        margin: { autoexpand: false, l: 45, r: 20, b: 30, t: 15, pad: 0 },
        showlegend: false
      };
      plotly.newPlot("singleValuesBoxPlot", this.boxPlotData1, layout, config);
    },
    createBoxPlotStation2(data) {
      this.boxPlotData2 = [];
      data.forEach(data => {
        this.boxPlotData2.push({
          type: "box",
          y: data.data,
          name: `${this.selectedStations[1].station_name}`,
          marker: {
            color: this.compareColors[1]
          }
        });
      });
      const config = this.getPLotConfigs();
      const layout = {
        margin: { autoexpand: false, l: 45, r: 20, b: 30, t: 15, pad: 0 },
        showlegend: false
      };
      plotly.newPlot("singleValuesBoxPlot2", this.boxPlotData2, layout, config);
    },
    createInSituPlot(data) {
      this.plotInSituData = [];
      data.forEach((data, idx) => {
        this.plotInSituData.push({
          type: "scatter",
          mode: "markers",
          marker: {
            color: this.compareColorsInSituData[idx],
            symbol: "diamond"
          },
          x: data.date_times,
          y: data.values,
          customdata: ["sourceInSituData" + [idx]]
        });
      });

      var graphDiv = document.getElementById("singleValuesPlot");
      plotly.addTraces(graphDiv, this.plotInSituData);

      graphDiv.on("plotly_relayout", function(eventdata) {
        this.yrange = [
          eventdata["yaxis.range[0]"],
          eventdata["yaxis.range[1]"]
        ];
      });
    },
    createInSituBoxPlot1(data) {
      this.boxPlotInSituDataData1 = [];
      data.forEach(data => {
        this.boxPlotInSituDataData1.push({
          type: "box",
          y: data.values,
          name: this.$t("inSitu.baselineInSituPlotTitle") + " 1",
          marker: {
            color: this.compareColorsInSituData[0]
          }
        });
      });

      plotly.addTraces("singleValuesBoxPlot", this.boxPlotInSituDataData1);
    },
    createInSituBoxPlot2(data) {
      this.boxPlotInSituData2 = [];
      data.forEach(data => {
        this.boxPlotInSituData2.push({
          type: "box",
          y: data.values,
          name: this.$t("inSitu.baselineInSituPlotTitle") + " 2",
          marker: {
            color: this.compareColorsInSituData[1]
          }
        });
      });

      plotly.addTraces("singleValuesBoxPlot2", this.boxPlotInSituData2);
    },
    removeTraceByCustomData() {
      const plotContainer = this.$refs.mainplot;
      let traceIndicesToRemove = [];

      if (plotContainer && plotContainer.data) {
        plotContainer.data.forEach((trace, index) => {
          if (trace.customdata) {
            traceIndicesToRemove.push(index);
            plotly.deleteTraces("singleValuesPlot", [2, 3]);
            plotly.deleteTraces("singleValuesBoxPlot", 1);
            plotly.deleteTraces("singleValuesBoxPlot2", 1);
          }
        });
      }
    },
    filterPlotDataByDateOrYear(plotData, filterBy) {
      const isDateRange = filterBy.length === 2 && filterBy[0].includes("-");

      if (isDateRange) {
        let filteredPlotData = JSON.parse(JSON.stringify(plotData));

        filteredPlotData.forEach(dataset => {
          let datetimesInSituData = [];
          let valuesInSituData = [];
          dataset.date_times.forEach((datetime, index) => {
            if (moment(datetime).isBetween(filterBy[0], filterBy[1])) {
              datetimesInSituData.push(datetime);
              valuesInSituData.push(dataset.values[index]);
            }
          });
          dataset.date_times = datetimesInSituData;
          dataset.values = valuesInSituData;
        });

        this.createInSituPlot(filteredPlotData);
        this.createInSituBoxPlot1([filteredPlotData[0]]);
        this.createInSituBoxPlot2([filteredPlotData[1]]);
      } else {
        let filteredPlotData = JSON.parse(JSON.stringify(plotData));

        filteredPlotData.forEach(dataset => {
          let datetimesInSituData = [];
          let valuesInSituData = [];
          dataset.date_times.forEach((datetime, index) => {
            if (filterBy.includes(datetime.split("-")[0])) {
              datetimesInSituData.push(datetime);
              valuesInSituData.push(dataset.values[index]);
            }
          });
          dataset.date_times = datetimesInSituData;
          dataset.values = valuesInSituData;
        });

        this.createInSituPlot(filteredPlotData);
        this.createInSituBoxPlot1([filteredPlotData[0]]);
        this.createInSituBoxPlot2([filteredPlotData[1]]);
      }
    }
  },
  watch: {
    async selectedStations() {
      this.loading = true;
      this.timeseries1 = await this.fetchAllStatistics(
        this.activeRegion.id,
        this.selectedStations[0].id
      );
      this.timeseries2 = await this.fetchAllStatistics(
        this.activeRegion.id,
        this.selectedStations[1].id
      );
      this.createPlot([this.timeseries1, this.timeseries2]);
      this.createInSituPlot([this.inSituData1, this.inSituData2]);

      this.createBoxPlotStation1([this.timeseries1]);
      this.createBoxPlotStation2([this.timeseries2]);

      this.createInSituBoxPlot1([this.inSituData1]);
      this.createInSituBoxPlot2([this.inSituData2]);

      this.loading = false;
    },
    showThresholds() {
      if (this.showThresholds) {
        this.layout = this.addThresholdLayout(
          this.layout,
          this.thresholds.threshold_alert,
          this.thresholds.threshold_warning
        );
        this.updatePlot(this.plotData, this.layout);
      } else {
        this.layout = this.getLayout(this.selectedStations[0].rastless_unit);
        this.updatePlot(this.plotData, this.layout);
      }
    },
    showInSituData(value) {
      this.loading = true;
      if (!value) {
        this.removeTraceByCustomData();
      } else {
        if (this.filterBy.length <= 0) {
          this.filterBy = this.combineYears;
        }
        this.filterPlotDataByDateOrYear(
          [this.inSituData1, this.inSituData2],
          this.filterBy
        );
      }
      this.loading = false;
    },
    yearsToFilterInSituData() {
      this.filterBy = this.yearsToFilterInSituData;
    },
    periodToFilterInSituData() {
      this.filterBy = this.periodToFilterInSituData;
    }
  },
  async created() {
    const [
      timeseries1,
      timeseries2,
      inSituData1,
      inSituData2
    ] = await Promise.allSettled([
      this.fetchAllStatistics(
        this.activeRegion.id,
        this.selectedStations[0].id
      ),
      this.fetchAllStatistics(
        this.activeRegion.id,
        this.selectedStations[1].id
      ),
      this.fetchInSituStationsData(
        this.selectedStations[0].virtual_station_id,
        this.selectedStations[0].rastless_layer_id
      ),
      this.fetchInSituStationsData(
        this.selectedStations[1].virtual_station_id,
        this.selectedStations[1].rastless_layer_id
      )
    ]);

    if (
      timeseries1.status === "fulfilled" &&
      timeseries2.status === "fulfilled" &&
      inSituData1.status === "fulfilled" &&
      inSituData2.status === "fulfilled"
    ) {
      this.timeseries1 = timeseries1.value;
      this.timeseries2 = timeseries2.value;
      this.inSituData1 = inSituData1.value;
      this.inSituData2 = inSituData2.value;

      //update years, combining years from props and insitu data
      const yearsFromInSituData1 = this.inSituData1.date_times.map(
        dateString => dateString.split("-")[0]
      );
      const yearsFromInSituData2 = this.inSituData2.date_times.map(
        dateString => dateString.split("-")[0]
      );

      if (
        yearsFromInSituData1.length ||
        yearsFromInSituData2.length ||
        (yearsFromInSituData1.length && yearsFromInSituData2.length)
      ) {
        this.hasInSituData = true;
      }

      const uniqueYearsSet = new Set([
        ...this.years,
        ...yearsFromInSituData1,
        ...yearsFromInSituData2
      ]);
      this.combineYears = Array.from(uniqueYearsSet).sort((a, b) => a - b);

      this.createPlot([this.timeseries1, this.timeseries2]);

      this.createInSituPlot([this.inSituData1, this.inSituData2]);

      this.createBoxPlotStation1([this.timeseries1]);
      this.createBoxPlotStation2([this.timeseries2]);

      this.createInSituBoxPlot1([this.inSituData1]);
      this.createInSituBoxPlot2([this.inSituData2]);

      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
