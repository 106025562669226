var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"height":"275px"}},[_c('v-card-text',{staticClass:"black--text pt-2"},[_c('v-row',{staticStyle:{"align-items":"center"},attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('span',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.selectedStations[0].rastless_product.toUpperCase())+" - "+_vm._s(_vm.$t("baseline.singleValues")))])]),(!_vm.loading)?_c('v-col',{staticClass:"pl-0",staticStyle:{"min-width":"200px"},attrs:{"cols":"auto"}},[_c('v-switch',{staticClass:"mt-0 pa-0 pl-1 float-left text-body-2",attrs:{"label":_vm.showInSituData
              ? _vm.$t('baseline.hideInsitu')
              : _vm.$t('baseline.showInsitu'),"color":"primary","hide-details":"","disabled":!_vm.hasInSituData},model:{value:(_vm.showInSituData),callback:function ($$v) {_vm.showInSituData=$$v},expression:"showInSituData"}})],1):_vm._e(),_c('v-spacer'),(!_vm.loading)?_c('v-col',{staticClass:"pr-2",staticStyle:{"min-width":"100px"},attrs:{"cols":"auto"}},[_c('period-filter',{attrs:{"years":_vm.showInSituData ? _vm.combineYears : _vm.years},on:{"filteredYears":_vm.changeYears,"filteredPeriod":_vm.changeTimePeriod}})],1):_vm._e()],1)],1),_c('v-row',{staticClass:"px-3"},[_c('div',{ref:"mainplot",style:({
        top: 0,
        bottom: 0,
        width: 'calc(60% - 5px)',
        height: '200px',
        marginRight: '5px'
      }),attrs:{"id":"singleValuesPlot"}}),_c('div',{ref:"plot",style:({
        top: 0,
        bottom: 0,
        width: '20%',
        height: '200px'
      }),attrs:{"id":"singleValuesBoxPlot"}}),_c('div',{ref:"plot",style:({
        top: 0,
        bottom: 0,
        width: '20%',
        height: '200px'
      }),attrs:{"id":"singleValuesBoxPlot2"}}),(_vm.loading)?_c('v-overlay',{attrs:{"opacity":"0.1","absolute":""}},[_c('div',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"64"}})],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }