<template>
  <v-card style="height: 282px">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center" no-gutters>
        <v-col>
          <span class="text-h6 font-weight-regular">
            {{ selectedStations[0].rastless_product.toUpperCase() }} -
            {{ $t("baseline.annualComparison") }}</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto" class="px-2">
          <span class="font-weight-bold"
            >{{ $t("baseline.referencePeriod") }}:</span
          >
        </v-col>
        <v-col cols="auto" class="px-2">
          <span>{{ $t("baseline.start") }}: </span>
        </v-col>
        <v-col cols="auto" class="px-2">
          <v-select
            style="width: 150px"
            dense
            hide-details
            :items="years"
            v-model="selectedStartYear"
            label="Layer"
            solo
            return-object
          ></v-select>
        </v-col>
        <v-col cols="auto" class="px-2">
          <span>{{ $t("baseline.end") }}: </span>
        </v-col>
        <v-col cols="auto" class="pl-2">
          <v-select
            style="width: 150px"
            dense
            hide-details
            :items="years"
            v-model="selectedEndYear"
            label="Layer"
            solo
          ></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      ref="plot"
      id="annualComparisonPlot"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '70%'
      }"
    ></div>
  </v-card>
</template>

<script>
import baselineMixin from "@/core/mixins/baseline.mixin";
import { mapState } from "vuex";
import plotly from "plotly.js-dist";

export default {
  name: "AnnualComparisonCompare",
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    region: null,
    years: { type: Array }
  },
  mixins: [baselineMixin],
  data: () => ({
    selectedStartYear: null,
    selectedEndYear: null,
    data: {},
    boxPlotData: []
  }),
  computed: {
    ...mapState("baseline", ["selectedStations", "compareColors"])
  },
  methods: {
    updatePlot(data, layout) {
      plotly.react("annualComparisonPlot", data, layout);
    },
    updateDatarange(data) {
      this.boxPlotData.forEach((stationBoxData, stationIdx) => {
        let x = [];
        let y = [];
        stationBoxData.x.forEach((year, index) => {
          if (!year.includes("-")) {
            x.push(year);
            y.push(stationBoxData.y[index]);
          }
        });
        //add new values for yearrange
        Object.keys(data[stationIdx]).forEach(year => {
          data[stationIdx][year].forEach(datapoint => {
            x.unshift(`${this.selectedStartYear}-${this.selectedEndYear}`);
            y.unshift(datapoint);
          });
        });
        stationBoxData.x = x;
        stationBoxData.y = y;
        this.boxPlotData[stationIdx] = stationBoxData;
      });
      this.updatePlot(this.boxPlotData, this.layout);
    },
    createBoxPlotData(data) {
      this.boxPlotData = [];
      data.forEach((station, idx) => {
        let stationData = {};
        let x = [];
        let y = [];
        Object.keys(station).forEach(year => {
          station[year].forEach(datapoint => {
            x.push(`${this.selectedStartYear}-${this.selectedEndYear}`);
            x.push(year);
            y.push(datapoint);
            y.push(datapoint); // add double because of "year range and group boxplot system"
          });
          stationData = {
            name: `${this.selectedStations[idx].station_name} `,
            x: x,
            y: y,
            type: "box",
            marker: { color: this.compareColors[idx] }
          };
        });
        this.boxPlotData.push(stationData);
      });
      this.config = this.getPLotConfigs();
      this.layout = this.getCompareBoxGroupLayout(
        this.selectedStations[0].rastless_unit
      );

      plotly.newPlot(
        "annualComparisonPlot",
        this.boxPlotData,
        this.layout,
        this.config
      );
    }
  },
  watch: {
    async selectedStations() {
      this.dataStation1 = await this.fetchStatistics(
        this.region,
        this.selectedStations[0].id,
        "yearly"
      );
      this.dataStation2 = await this.fetchStatistics(
        this.region,
        this.selectedStations[1].id,
        "yearly"
      );
      this.selectedStartYear = this.years[0];
      this.selectedEndYear = this.years[this.years.length - 1];
      this.createBoxPlotData([this.dataStation1, this.dataStation2]);
    },
    async selectedEndYear() {
      this.dataStation1 = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStations[0].id,
        "yearly",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.dataStation2 = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStations[1].id,
        "yearly",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.updateDatarange([this.dataStation1, this.dataStation2]);
    },
    async selectedStartYear() {
      this.dataStation1 = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStations[0].id,
        "yearly",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.dataStation2 = await this.fetchStatisticsWithinTimeRange(
        this.region,
        this.selectedStations[1].id,
        "yearly",
        `${this.selectedStartYear}-01-01`,
        `${this.selectedEndYear}-12-31`
      );
      this.updateDatarange([this.dataStation1, this.dataStation2]);
    },
    showThresholds() {
      if (this.showThresholds) {
        this.layout = this.addThresholdLayout(
          this.layout,
          this.thresholds.threshold_alert,
          this.thresholds.threshold_warning
        );
        this.updatePlot(this.boxPlotData, this.layout);
      } else {
        this.layout = this.getCompareBoxGroupLayout(
          this.selectedStations[0].rastless_unit
        );
        this.updatePlot(this.boxPlotData, this.layout);
      }
    }
  },
  async created() {
    this.dataStation1 = await this.fetchStatistics(
      this.region,
      this.selectedStations[0].id,
      "yearly"
    );
    this.dataStation2 = await this.fetchStatistics(
      this.region,
      this.selectedStations[1].id,
      "yearly"
    );
    this.selectedStartYear = this.years[0];
    this.selectedEndYear = this.years[this.years.length - 1];
    this.createBoxPlotData([this.dataStation1, this.dataStation2]);
  }
};
</script>

<style scoped></style>
