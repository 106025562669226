<template>
  <v-main
    fluid
    class="px-5 mt-5 d-flex flex-column flex-grow-1 fill-height overflow-y-auto overflow-x-hidden"
  >
    <v-row v-if="station1 && station2">
      <v-col cols="auto">
        <div>
          <v-btn class="pb-1" icon color="#173466" @click="$router.go(-1)">
            <v-icon large>mdi-chevron-left</v-icon>
          </v-btn>
          <span style="color: #173466" class="text-h5">
            {{ $t("baseline.compare") }}
            <span :style="{ color: compareColors[0] }">{{
              station1.station_name
            }}</span>
            {{ $t("baseline.with") }}
            <span :style="{ color: compareColors[1] }">{{
              station2.station_name
            }}</span>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="product.product">
      <v-col cols="3" style="elevation: 4turn">
        <v-select
          dense
          hide-details
          v-model="product"
          :items="productLayerSelection"
          :value="product"
          :item-text="item => item.product.toUpperCase() + ' - ' + item.title"
          label="Layer"
          solo
          return-object
        ></v-select>
      </v-col>
      <v-spacer />
    </v-row>
    <v-row v-if="!loading" class="mt-3 ml-0">
      <v-col cols="2" style="elevation: 4turn">
        <v-row no-gutters>
          <v-select
            dense
            hide-details
            v-model="station1"
            :items="baselineStations"
            :value="station1"
            :item-text="item => item.station_name"
            :label="$t('baseline.compare')"
            return-object
          >
            <template v-slot:prepend>
              <v-icon :color="compareColors[0]">mdi-circle</v-icon>
            </template>
          </v-select>
        </v-row>
      </v-col>
      <v-col cols="2" class="ml-4" style="elevation: 4turn">
        <v-row no-gutters>
          <v-select
            dense
            hide-details
            v-model="station2"
            :items="baselineStations"
            :value="station2"
            :label="$t('baseline.to')"
            :item-text="item => item.station_name"
            return-object
          >
            <template v-slot:prepend>
              <v-icon class="mr-1" :color="compareColors[1]">mdi-circle</v-icon>
            </template>
          </v-select>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="station1 && station2 && years.length">
      <annual-comparison-compare
        class="mt-6"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :years="years"
        :region="regionId"
      ></annual-comparison-compare>
      <monthly-values-compare
        class="mt-6"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :years="years"
        :region="regionId"
      ></monthly-values-compare>
      <comparison
        class="mt-6"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :years="years"
        :region="regionId"
      ></comparison>
      <v-card class="mt-6">
        <v-card-title class="text-h6 font-weight-regular py-2 px-4">
          {{ selectedStations[0].rastless_product.toUpperCase() }} -
          {{ $t("baseline.monthlyMap") }}
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col class="pr-1">
              <layer-map-compare
                :selected-product="station1"
                :container="'compare-left'"
              ></layer-map-compare>
            </v-col>
            <v-col class="pl-1">
              <layer-map-compare
                :selected-product="station2"
                :container="'compare-right'"
              ></layer-map-compare>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <single-values-compare
        class="mt-6"
        :show-thresholds="showThresholds"
        :thresholds="productThreshold"
        :years="years"
      >
      </single-values-compare>
      <v-row class="mt-6" no-gutters>
        <v-col class="pr-1">
          <statistics-compare
            class="mb-10"
            :thresholds="productThreshold"
            :years="years"
            :region="regionId"
            :station="station1"
          >
          </statistics-compare>
        </v-col>
        <v-col class="pl-1">
          <statistics-compare
            class="mb-10"
            :thresholds="productThreshold"
            :years="years"
            :region="regionId"
            :station="station2"
          >
          </statistics-compare>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import regionMixin from "@/core/mixins/region.mixin";
import baselineMixin from "@/core/mixins/baseline.mixin";
import { mapActions, mapGetters, mapState } from "vuex";
import MonthlyValuesCompare from "@/core/components/baseline/baselineCompare/MonthlyValuesCompare.vue";
import Comparison from "@/core/components/baseline/baselineCompare/Comparison.vue";
import SingleValuesCompare from "@/core/components/baseline/baselineCompare/SingleValuesCompare.vue";
import LayerMapCompare from "@/core/components/baseline/baselineCompare/LayerMapCompare.vue";
import StatisticsCompare from "@/core/components/baseline/baselineCompare/StatisticsCompare.vue";
import AnnualComparisonCompare from "@/core/components/baseline/baselineCompare/AnnualComparisonCompare.vue";

export default {
  name: "BaselineCompareDetails",
  components: {
    AnnualComparisonCompare,
    StatisticsCompare,
    LayerMapCompare,
    SingleValuesCompare,
    Comparison,
    MonthlyValuesCompare
  },
  mixins: [regionMixin, baselineMixin],
  data: () => ({
    station1: null,
    station2: null,
    layerSelection: [],
    baselineStations: [],
    showThresholds: false,
    regionThresholds: [],
    productThreshold: {},
    productLayerSelection: [],
    regionId: null,
    years: [],
    loading: true
  }),
  methods: {
    ...mapActions("management", ["getOrFetchAccessToken"]),
    ...mapActions("baseline", ["setSelectedStations", "updateProduct"]),
    defineProductThresholds() {
      const thresholds = this.regionThresholds.filter(
        productThreshold => productThreshold.product === this.product.product
      );

      return thresholds[0];
    }
  },
  computed: {
    ...mapGetters("baseline", ["getProduct"]),
    ...mapState("baseline", ["product", "selectedStations", "compareColors"]),
    product: {
      get() {
        return this.getProduct;
      },
      set(product) {
        this.updateProduct(product);
      }
    }
  },
  watch: {
    async product() {
      this.loading = true;
      this.productThreshold = this.defineProductThresholds();
      this.baselineStations = await this.fetchBaselineStationsForLayer(
        this.regionId,
        this.product.layerId,
        true
      );
      this.station1 = this.baselineStations[0];
      this.station2 = this.baselineStations[1];
      this.setSelectedStations([this.station1, this.station2]);
      const yearData = await this.fetchStatistics(
        this.regionId,
        this.station1.id,
        "yearly"
      );
      this.years = Object.keys(yearData);
      this.loading = false;
    },
    station1() {
      // this.setSelectedStations({ index: 0, station: this.station1 });
      this.setSelectedStations([this.station1, this.station2]);
    },
    station2() {
      // this.setSelectedStations({ index: 1, station: this.station2 });
      this.setSelectedStations([this.station1, this.station2]);
    }
  },
  created() {
    this.regionId = this.$route.params.regionId;
    this.getOrFetchAccessToken().then(async () => {
      this.fetchLayers(this.regionId).then(async results => {
        this.productLayerSelection = results;
        if (!this.product?.layerId) {
          this.product = this.productLayerSelection[0];
        }
        this.fetchBaselineStationsForLayer(
          this.regionId,
          this.product.layerId,
          true
        ).then(async response => {
          this.baselineStations = response;
          if (this.selectedStations.length) {
            this.station1 = this.selectedStations[0];
            this.station2 = this.selectedStations[1];
          } else {
            this.station1 = this.baselineStations[0];
            this.station2 = this.baselineStations[1];
          }
          const yearData = await this.fetchStatistics(
            this.regionId,
            this.station1.id,
            "yearly"
          );
          this.years = Object.keys(yearData);
        });
        this.fetchRegionThresholds(this.regionId).then(result => {
          this.regionThresholds = result;
          this.productThreshold = this.defineProductThresholds();
          this.loading = false;
        });
      });
    });
  }
};
</script>

<style scoped></style>
