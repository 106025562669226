<template>
  <v-card style="height: 282px">
    <v-card-text class="black--text pt-2">
      <v-row style="align-items: center" no-gutters>
        <v-col>
          <span class="text-h6 font-weight-regular">
            {{ selectedStations[0].rastless_product.toUpperCase() }} -
            {{ $t("baseline.monthlyComparison") }}</span
          >
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="auto">
          <year-filter
            :years="years"
            @filteredYears="changeYears"
          ></year-filter>
        </v-col>
      </v-row>
    </v-card-text>
    <div
      ref="plot"
      id="monthly-comparison"
      :style="{
        top: 0,
        bottom: 0,
        width: '100%',
        height: '70%'
      }"
    ></div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import baselineMixin from "@/core/mixins/baseline.mixin";
import plotly from "plotly.js-dist";
import YearFilter from "@/core/components/baseline/YearFilter.vue";

export default {
  name: "Comparison",
  components: { YearFilter },
  props: {
    showThresholds: { type: Boolean, default: () => false },
    thresholds: { type: Object },
    station: { type: Object },
    years: { type: Array },
    region: null
  },
  mixins: [baselineMixin],
  data: () => ({
    data: [],
    trace1: [],
    trace2: [],
    boxPlotData: []
  }),
  methods: {
    async createTrace(station, color, selectedYears) {
      let yearData = await this.fetchStatisticsWithinTimeRange(
        this.region,
        station.id,
        "monthly",
        `${this.years[0]}-01-01`,
        `${this.years[this.years.length - 1]}-12-31`
      );
      yearData = this.filterYears(yearData, selectedYears);
      let x = [];
      let y = [];
      this.monthsDict.forEach(async month => {
        const filtered = Object.keys(yearData).filter(key =>
          key.includes(`-${month.val}`)
        );
        if (filtered.length) {
          filtered.forEach(year => {
            yearData[year].forEach(value => {
              x.push(`${this.$t(`${month.label}`)}`);
              y.push(value);
            });
          });
        } else {
          x.push(`${this.$t(`${month.label}`)}`);
          y.push(undefined);
        }
      });
      return {
        x: x,
        y: y,
        name: `${station.station_name}`,
        type: "box",
        marker: { color: color }
      };
    },
    filterYears(data, selectedYears) {
      const newdata = Object.keys(data)
        .filter(key => selectedYears.includes(key.split("-")[0]))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: data[key]
          });
        }, {});
      return newdata;
    },
    async changeYears(filteredyears) {
      this.trace1 = await this.createTrace(
        this.selectedStations[0],
        this.compareColors[0],
        filteredyears
      );
      this.trace2 = await this.createTrace(
        this.selectedStations[1],
        this.compareColors[1],
        filteredyears
      );
      this.updatePlot([this.trace1, this.trace2], this.layout);
    },
    createBoxPlot() {
      const config = this.getPLotConfigs();
      this.layout = this.getBoxGroupLayout(
        this.selectedStations[0].rastless_unit
      );
      plotly.newPlot(
        "monthly-comparison",
        [this.trace1, this.trace2],
        this.layout,
        config
      );
    },
    updatePlot(data, layout) {
      plotly.react("monthly-comparison", data, layout);
    }
  },
  computed: {
    ...mapState("baseline", ["selectedStations", "monthsDict", "compareColors"])
  },
  watch: {
    async selectedStations() {
      this.trace1 = await this.createTrace(
        this.selectedStations[0],
        this.compareColors[0],
        this.years
      );
      this.trace2 = await this.createTrace(
        this.selectedStations[1],
        this.compareColors[1],
        this.years
      );
      this.updatePlot([this.trace1, this.trace2], this.layout);
    },
    async selectedYear() {
      this.selectedYear = this.years[0];
      this.trace1 = await this.createTrace(
        this.selectedStations[0],
        this.compareColors[0],
        this.years
      );
      this.trace2 = await this.createTrace(
        this.selectedStations[1],
        this.compareColors[1],
        this.years
      );
      this.updatePlot([this.trace1, this.trace2], this.layout);
    },
    showThresholds() {
      if (this.showThresholds) {
        this.layout = this.addThresholdLayout(
          this.layout,
          this.thresholds.threshold_alert,
          this.thresholds.threshold_warning
        );
        this.updatePlot([this.trace1, this.trace2], this.layout);
      } else {
        this.layout = this.getBoxGroupLayout();
        this.updatePlot([this.trace1, this.trace2], this.layout);
      }
    }
  },
  async created() {
    this.trace1 = await this.createTrace(
      this.selectedStations[0],
      this.compareColors[0],
      this.years
    );
    this.trace2 = await this.createTrace(
      this.selectedStations[1],
      this.compareColors[1],
      this.years
    );
    this.createBoxPlot(this.trace1, this.trace2);
  }
};
</script>

<style scoped></style>
